<template>
  <BasicModal>
    <template #content>
      <BasicInputField ref="emailInput"
                       :field-title="$t('EMAIL_ADDRESS')"
                       :field-name="'email'"
                       :field-type="'email'"
                       :input-icon-error="require('../../../assets/icons/icn_error.svg')"
                       :input-icon="require('../../../assets/icons/icn_email.svg')"
                       :callback="validateEmail"
                       :placeholder="$t('ENTER_EMAIL')"
                       :api-error-text="(emailError ? 'Ongeldig e-mailadres' : '') + (emailInUse && !emailError ? 'Dit e-mailadres is al in gebruik' : '')"
                       class="email-field"/>
    </template>
  </BasicModal>
</template>

<script>
import BasicModal from '@/components/elements/basicModal/BasicModal'
import { ref } from '@vue/reactivity'
import BasicInputField from '@/components/elements/inputFields/BasicInputField'

export default {
  name: 'SendNewRequest',
  components: {
    BasicInputField,
    BasicModal,
  },
  props: {
    onEmailInput: {
      type: Function,
      required: true
    },
    emailInUse: {
      type: Boolean,
      default: false
    },
  },
  setup(props) {
    const emailInput = ref(null)
    const emailError = ref(false)

    function validateEmail(email) {
      const isInputValid = validateField()
      if (isInputValid) {
        emailError.value = false
        props.onEmailInput(email)
      } else {
        emailError.value = true
      }
    }

    function validateField() {
      // The field should not be valid if it's invalid or empty.
      return emailInput.value.$refs.email.checkValidity() && emailInput.value.$refs.email.value !== ''
    }

    return {
      emailError,
      emailInput,
      validateEmail,
      validateField,
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.email-field {
  margin-bottom: rem(40);
  text-align: left;
  padding-left: rem(20);
}

.form-input {
  border: none;
  border-radius: rem(8);
  background-color: white;
  box-shadow: rem(2) rem(2) 0 0 rgba(0, 0, 0, 0.1);
  color: var(--blue_dark_01);
  font-size: rem(16);
  height: rem(46);
  width: 100%;
  padding-left: rem(16);
}

</style>

<template>
  <div class="content-container">
    <!-- Page title and the school address. -->
    <PageTitle :title="$t('MY_SCHOOL.TITLE')" class="page-title">
      <template #extra-content>
        <!-- TODO: On click, the 'Send new request' popup is displayed (6.1.0). -->
        <BasicButton :button-text="$t('MY_SCHOOL.SEND_NEW')" class="pink-button" @click="toggleSendNewRequests()"/>
      </template>
    </PageTitle>
    <p class="page-section-text school-address"><span class="school-address-school-name">{{ profile.schoolName }}</span> - {{ profile.schoolAddress }}</p>

    <!-- New Requests -->
    <h1 class="page-section-title section-title">{{ $t('MY_SCHOOL.NEW_REQUESTS') }}</h1>
    <div v-if="unapprovedTeachers.length === 1"
         class="page-section-text section-text">
      <i18n-t keypath="MY_SCHOOL.NEW_REQUESTS_TEXT_SINGULAR" tag="div">
        <template #requests>
          <span style="font-weight: 500"> één </span>
        </template>
      </i18n-t >
    </div>
    <div v-else-if="unapprovedTeachers.length > 1"
         class="page-section-text section-text">
      <i18n-t keypath="MY_SCHOOL.NEW_REQUESTS_TEXT_PLURAL" tag="div">
        <template #requests>
          <span style="font-weight: 500"> {{ unapprovedTeachers.length }} </span>
        </template>
      </i18n-t >
    </div>
    <p v-else class="page-section-text">{{ $t('MY_SCHOOL.NO_NEW_REQUESTS') }}</p>

    <CustomTable v-if="unapprovedTeachers.length > 0"
                 :options="newRequestTableOptions"
                 :less-padding="true"
                 :table-data="unapprovedTeachers"
                 :table-class="'my-school'">
      <template #email="props">
        <a :href="'mailto:' + props.rowData.email">{{ props.rowData.email }}</a>
      </template>
      <template #name="props">
        <div>{{ props.rowData.name ?? $t('MY_SCHOOL.INVITE_SENT') }}</div>
      </template>
      <template #date="props">
        <div>{{ getDateString(props.rowData.createdAt) }}</div>
      </template>
      <template #actions="props">
        <div class="action-button-group">
          <ActionButton :text="'Afwijzen'"
                        :icon="require('../../../assets/icons/icn_reject.svg')"
                        custom-class="button-red reject-button"
                        class="action"
                        @click="beforeRequestRefusal(props.rowData)"/>
          <ActionButton :text="'Accepteren'"
                        :icon="require('../../../assets/icons/icn_accept.svg')"
                        custom-class="button-green accept-button"
                        class="action"
                        @click="beforeRequestAcceptance(props.rowData)"/>
        </div>
      </template>
    </CustomTable>

    <!-- Verified colleagues -->
    <h1 class="page-section-title new-requests">{{ $t('MY_SCHOOL.VERIFIED_COLLEAGUES') }}</h1>
    <CustomTable
      :less-padding="true"
      :options="verifiedColleaguesTableOptions"
      :table-data="approvedTeachers"
      :table-class="'my-school'">
      <template #email="props">
        <a :href="'mailto:' + props.rowData.email">{{ props.rowData.email }}</a>
      </template>
      <template #name="props">
        <div>{{ props.rowData.name ?? $t('MY_SCHOOL.INVITE_SENT') }}</div>
      </template>
      <template #date="props">
        <div>{{ getDateString(props.rowData.createdAt) }}</div>
      </template>
      <!-- <template #actions="props">
        <div class="action-button-group">
          <ActionButton :text="'Verwijderen'"
                        :icon="require('../../../assets/icons/icn_delete.svg')"
                        custom-class="button-red"
                        class="action delete-button"
                        @click="removeTeacher(props.rowData)"/>
          <ActionButton :text="'Bewerken'"
                        :icon="require('../../../assets/icons/icn_edit.svg')"
                        custom-class="button-blue"
                        class="action edit-button"
                        @click="editTeacher(props.rowData)"/>
        </div>
      </template> -->
    </CustomTable>

    <!-- Modals included (Basic version): Refuse request (6.0.1), Accept request (6.0.2), Remove teacher (6.0.3). -->
    <BasicModal v-if="isBasicVersionDisplayed"/>

    <!-- Send new request (Basic with slot) -->
    <!-- TODO: NOT finished. Make sure the input is validated and data is returned to the parent. -->
    <SendNewRequest v-if="activeModal === MODAL_NAMES.SEND_NEW_REQUEST"
                    ref="sendNewRequestModal"
                    :email-in-use="emailInUse"
                    :on-email-input="getRequestEmail"/>
  </div>
</template>

<script>
import PageTitle from '@/components/elements/texts/PageTitle'
import CustomTable from '@/components/table/CustomTable'
import { computed, ref } from '@vue/reactivity'
import {
  getTableOptions,
  MY_SCHOOL_NEW_REQUESTS,
  MY_SCHOOL_VERIFIED_COLLEAGUES
} from '@/utils/helpers/customTable/CustomTableHelper'
import ActionButton from '@/components/table/ActionButton'
import BasicModal from '@/components/elements/basicModal/BasicModal'
import ModalController, { MODAL_NAMES } from '@/utils/helpers/basicModal/ModalController'
import { BASIC_MODAL_CONTENT } from '@/models/basicModal/BasicModalContent'
import { useStore } from 'vuex'
import SendNewRequest from '@/components/partials/mySchool/SendNewRequest'
import { ROUTE_NAMES_TEACHER_PORTAL } from '@/router/modules/teacherPortal'
import { useRouter } from 'vue-router'
import apiHandler from '@/utils/handlers/ApiHandler'
import { SET_TOAST_DATA } from '@/store/mutations'
import BasicButton from '@/components/elements/basic/BasicButton'
export default {
  name: 'MySchool',
  components: {
    SendNewRequest,
    BasicModal,
    ActionButton,
    CustomTable,
    PageTitle,
    BasicButton,
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    function retrieveTeacherData() {
      apiHandler.get('teacher/get-teacher-requests?includeInvites=true', null).then((response) => {
        unapprovedTeachers.value = response.data.unapprovedTeachers
        approvedTeachers.value = response.data.approvedTeachers
      })
    }
    // Call for startup
    retrieveTeacherData()

    /** Modals **/
    // Set up the modal controller.
    const modalController = ref(new ModalController())
    const activeModal = computed(() => store.getters.getActiveBasicModal)
    const isBasicVersionDisplayed = computed(() =>
      activeModal.value === MODAL_NAMES.REFUSE_REQUEST ||
      activeModal.value === MODAL_NAMES.ACCEPT_REQUEST ||
      activeModal.value === MODAL_NAMES.REMOVE_TEACHER
    )

    /** Send New Request **/
    const sendNewRequestModal = ref(null)
    const sendNewRequestEmail = ref('')

    const profile = computed(() => store.getters.getTeacherProfile)
    

    function toggleSendNewRequests() {
      modalController.value.setModal(BASIC_MODAL_CONTENT.SEND_NEW_REQUEST, onNewRequest, null, null, MODAL_NAMES.SEND_NEW_REQUEST)
    }

    function getRequestEmail(email) {
      sendNewRequestEmail.value = email.data
    }

    function onNewRequest() {
      if (sendNewRequestModal.value.validateField()) {
        sendNewRequest()
      }
    }

    const emailInUse = ref(false)

    function sendNewRequest() {
      emailInUse.value = false
      // TODO: Make API call and return promise.
      const formData = new FormData()
      formData.append('email', sendNewRequestEmail.value)
      apiHandler.post('teacher/send-request', formData).then(response => {
        if (response) {
          modalController.value.resetActiveModal()
          store.commit(SET_TOAST_DATA, { src: require('../../../assets/icons/icn_successful.svg'), text: `Verzoek verstuurd naar ${sendNewRequestEmail.value}` })
        } else {
          emailInUse.value = true
        }
      }).catch(error => {
        console.log('error', error)
        emailInUse.value = true
      })
    }

    /** New Requests **/
    const unapprovedTeachers = ref([])

    // Displays the warning popup before the user refuses the request.
    function beforeRequestRefusal(request) {
      const email = request.email
      modalController.value.setModal(BASIC_MODAL_CONTENT.REFUSE_REQUEST(email), refuseRequest, null, request, MODAL_NAMES.REFUSE_REQUEST)
    }

    function beforeRequestAcceptance(request) {
      const email = request.email
      modalController.value.setModal(BASIC_MODAL_CONTENT.ACCEPT_REQUEST(email), acceptRequest, null, request, MODAL_NAMES.ACCEPT_REQUEST)
    }

    function refuseRequest(request) {
      const formData = new FormData()
      formData.append('teacherId', request.id)
      const promise = apiHandler.post('teacher/decline-teacher', formData)

      promise.then(response => {
        console.log(response)
        modalController.value.resetActiveModal()
        retrieveTeacherData()
      })

      return promise
    }

    function acceptRequest(request) {
      const formData = new FormData()
      formData.append('teacherId', request.id)
      const promise = apiHandler.post('teacher/approve-teacher', formData)

      promise.then(() => {
        modalController.value.resetActiveModal()
        retrieveTeacherData()
      })

      return promise
    }

    /** Verified Colleagues **/
    const approvedTeachers = ref([])

    function editTeacher(teacher) {
      router.push({ name: ROUTE_NAMES_TEACHER_PORTAL.EDIT_TEACHER, params: { teacherId: teacher.id } })
    }

    function removeTeacher(teacher) {
      const email = teacher.email
      const id = teacher.id
      modalController.value.setModal(BASIC_MODAL_CONTENT.REMOVE_TEACHER(email), () => deleteTeacher(id), null, id, MODAL_NAMES.REMOVE_TEACHER)
    }

    function deleteTeacher(teacherId) {
      modalController.value.resetActiveModal()
      retrieveTeacherData()
    }
    function getDateString(apiDate) {
      const date = new Date(apiDate)
      return date.toLocaleDateString()
    }

    return {
      retrieveTeacherData,
      activeModal,
      MODAL_NAMES,
      modalController,
      isBasicVersionDisplayed,
      getDateString,
      profile,

      /** Send New Request **/
      sendNewRequestModal,
      toggleSendNewRequests,
      getRequestEmail,
      emailInUse,

      /** New Requests **/
      newRequestTableOptions: getTableOptions(MY_SCHOOL_NEW_REQUESTS),
      unapprovedTeachers,
      beforeRequestRefusal,
      beforeRequestAcceptance,

      /** Verified Colleagues **/
      verifiedColleaguesTableOptions: getTableOptions(MY_SCHOOL_VERIFIED_COLLEAGUES),
      approvedTeachers,
      editTeacher,
      removeTeacher,
      deleteTeacher,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";

.edit-button {
  margin-bottom: 0;
  height: rem(33);
}

.delete-button {
  margin-bottom: 0;
  height: rem(33);
}

.accept-button {
  margin-bottom: 0;
}

.reject-button {
  margin-bottom: 0;
}

.section-title {
  margin-bottom: rem(11);
}

.new-requests {
  margin-bottom: rem(24);
  margin-top: rem(56);
}

.section-text {
  margin-bottom: rem(32);
}

.school-address {
  margin-bottom: rem(32);
  margin-top: rem(8);

  &-school-name {
    font-weight: 500;
  }
}
</style>
